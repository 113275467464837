import React from "react"
import { Typography, Grid, ThemeProvider, Button, makeStyles } from "@material-ui/core"
import theme from "../theme"
import "../../../components/globalStyles.css"
import * as ROUTE from "../Links"
import * as S from '../../GlobalStyledComponents'

const useStyles = makeStyles(theme => ({

  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  formHeader:{
   textAlign:"center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      textAlign:"center",
    },
  },
  buttonsVertical: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems:"center",
    paddingTop:theme.spacing(3)

  },
  buttonsHorizontal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems:"center",

  },
  minimalMarginPadding:{
    marginTop:0,
    marginBottom:0,
    padding:theme.spacing(0),

  },

  buttonShort: {

    margin:theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      minWidth:"256px",
    },
  },
  buttonWide: {

    margin:theme.spacing(1),
    width:"100%",

  },
  errorMessage: {
    color: "#FF3333",
    fontSize: "1rem",
    fontWeight: 300,
    textAlign:"center",
  },


}))
/**
 *
 * @param {TypeProp} props
 * @returns {Object} React component
 */
export default function Intro(props) {
  const classes = useStyles()
  //validate if form is completed and next button should be activated
  let route
  if(props.country==="DE"){
    route = ROUTE.SummaryDENoAuth
  }else{
    route = ROUTE.SummaryCHNoAuth
  }



    return (
      <>
        <ThemeProvider theme={theme}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.formHeader}>
                  {props.txt.Title}
              </Typography>
              <Typography variant="body1" className={classes.formHeader}>
              {props.txt.SubTitle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" dangerouslySetInnerHTML ={{__html: props.txt.Body1}} />

            </Grid>

          <Grid item xs={12}>
              <Typography variant="body1" dangerouslySetInnerHTML={{__html: props.txt.Body2}} />

            </Grid>
          <Grid item xs={12}>
              <div className={classes.buttonsHorizontal}>
                
                <S.GTMButtonPrimary
                  id="ob-opensummarywithauth-btn"
                  variant="contained"
                  color="primary"
                  href={route}
                  target="_blank" 
                  rel='noopener noreferrer'
                >
                  {props.txt.btnTxt}
                </S.GTMButtonPrimary>
               
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" dangerouslySetInnerHTML={{__html: props.txt.Body3}} />

            </Grid>
          </Grid>          
        </ThemeProvider>
      </>
    )
  
}
