import React from "react"
import { withFB } from "../../Firebase/firebasecontext"

import { ThemeProvider } from "@material-ui/core/styles"

import theme from "../theme"

import Paper from "@material-ui/core/Paper"

import useSharedStyles from "../FormStyles"
import logo from "../../../../src/images/DoryGo_logo.png"
import OnboardingCompletedWithAuth from "./OnboardingCompletedWithAuth"
import OnboardingCompletedNoAuth from "./OnboardingCompletedNoAuth"



export function FormOnboardingBase(props) {
  const classes = useSharedStyles()

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.layout}>
          <Paper className={classes.paper}>
            <div className={classes.logoBox}>
              <a href="https://dorygo.com">
                <img src={logo} width="100%" className={classes.logo} />
              </a>

              <a href="https://dorygo.com">Zurück zur Startseite</a>
            </div>

            {props.auth === "yes" && <OnboardingCompletedWithAuth txt={props.txt.AuthFormSigned} country={props.country} language={props.language}/>}
            {props.auth === "no" && <OnboardingCompletedNoAuth txt={props.txt.AuthFormNotSigned} country={props.country} language={props.language}/>}
          </Paper>
        </div>
      </ThemeProvider>
    </>
  )
}

const FormOnboarding = withFB(FormOnboardingBase)

export default FormOnboarding
