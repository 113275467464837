import React from 'react'

import LayoutOB from '../components/layoutob.js'
import { useLocation } from '@reach/router'
import styled from "styled-components"

import FormOnboardingCompleted from '../components/Form/onboardingCompleted/FormOnboardingCompleted'
import { deDE } from "../lang/de-DE"
import { deCH } from "../lang/de-CH"
import { frCH } from "../lang/fr-CH"

const Wrapper = styled.div`
display:flex;
justify-content:flex-start;
align-items:flex-start;
flex: 1 0 auto;
min-height: 80vh;
`

export default function Success() {

    const location = useLocation();
    var searchParams = new URLSearchParams(location.search);
    var countryParam = searchParams.get("country");
    var authParam = searchParams.get("auth");
    var langParam = searchParams.get("language");

    let txt

    if (countryParam==="DE"){
        txt=deDE.Success
    }else{
        txt=deCH.Success
    }




    return (
        <LayoutOB location={countryParam} language={langParam}>
        <Wrapper>
            <FormOnboardingCompleted country={countryParam} txt={txt} auth={authParam} language={langParam}/>
            </Wrapper>
        </LayoutOB>
    )
}


//<FormPage/>