export const SummaryCHNoAuth="https://dorygo.com/forms/Zusammenfassung.pdf"
export const SummaryCHFRNoAuth="https://dorygo.com/forms/ResumeCHFR.pdf"
export const SummaryDENoAuth="https://dorygo.com/forms/ZusammenfassungDe.pdf"
export const SummaryCHWithAuth="https://dorygo.com/forms/Zusammenfassung.pdf"
export const SummaryCHFRWithAuth="https://dorygo.com/forms/ResumeCHFR.pdf"
export const SummaryDEWithAuth="https://dorygo.com/forms/ZusammenfassungDe.pdf"
export const AuthFormChDe = "https://sign.zoho.eu/signform?form_link=234b4d535f49562318529d63be2288deac5d5b6a268b7369d372acaced9bdd61cdeec0d2fa614bf3dda237023b88d66d3eff50aab13ad813a5cb8d887df0e9225994875d37acd500#"
export const AuthFormChFr = "https://sign.zoho.eu/signform?form_link=234b4d535f4956231352902e38818b06a31967b2a44a2ff4157e492e7f54c8912fcb478e6a6ee6a249da3d306891ea2f333acf06965dcbc0b07de9bd3b2a49e181ee7964dd3dca13#"
export const AuthFormDe = "https://sign.zoho.eu/signform?form_link=234b4d535f49562314863a4278f2236a50620f0daa87eac1cab6afec7c4681e7c621ac60bf60804c025a9cbccee94677c149832259ef1673cfbfad84a8148a78637a5cc0aa0993d8#"
export const SuccessPageNoAuth ="/success/?auth=no"
export const STARTONBOARDING='/onboarding'